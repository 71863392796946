import React from "react";
import HeroSection from "@src/components/partials/badges/HeroSection";
import ExpertBadgesSection from "@src/components/partials/badges/ExpertBadgesSection";
import TeachersResourcesSection from "@src/components/partials/badges/TeachersResourcesSection";
import ClubsSection from "@src/components/partials/badges/ClubsSection";
import { getSearchParams } from "gatsby-query-params";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const Badges = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_badges {
          hero_badge_title
          hero_badge_button_text
          hero_badge_background_url
          hero_title
          hero_text
          hero_button_text
          hero_button_url
          hero_image {
            id
            filename_download
            filename_disk
          }
          hero_image_alt
          expert_image {
            id
            filename_download
            filename_disk
          }
          expert_image_alt
          expert_icon {
            id
            filename_download
            filename_disk
          }
          expert_icon_alt
          expert_title
          expert_text
          expert_button_text
          expert_button_url
          teachers_title
          teachers_text
          teachers_button_text
          teachers_background_image_url
          teachers_button_url
          clubs_title
          clubs_mentor_title
          clubs_mentor_text
          clubs_mentor_button_text
          clubs_mentor_button_url
          clubs_ambassador_title
          clubs_ambassador_text
          clubs_ambassador_button_text
          clubs_ambassador_button_url
          clubs_background_image_url
        }
        badges {
          name
          id
          description
          category
          congrats_message
          image {
            id
            filename_disk
          }
          poster_pack
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_badges: {
        hero_image,
        hero_badge_title,
        hero_badge_button_text,
        hero_image_alt,
        hero_badge_background_url,
        hero_title,
        hero_text,
        hero_button_text,
        hero_button_url,
        expert_image,
        expert_image_alt,
        expert_icon,
        expert_icon_alt,
        expert_title,
        expert_text,
        expert_button_text,
        expert_button_url,
        teachers_title,
        teachers_text,
        teachers_button_text,
        clubs_title,
        clubs_mentor_title,
        clubs_mentor_text,
        clubs_mentor_button_text,
        clubs_ambassador_title,
        clubs_ambassador_text,
        clubs_ambassador_button_text,
        teachers_background_image_url,
        teachers_button_url,
        clubs_background_image_url,
        clubs_mentor_button_url,
        clubs_ambassador_button_url,
      },
      badges,
    },
  } = data;

  const params = getSearchParams();

  const HeroSectionProps = {
    hero_badge_title,
    hero_badge_button_text,
    hero_badge_background_url,
    hero_title,
    hero_text,
    hero_button_text,
    hero_button_url,
    hero_image,
    hero_image_alt,
    searchParams: params,
    badges: badges,
  };

  const ExpertBadgesSectionProps = {
    expert_image,
    expert_image_alt,
    expert_icon,
    expert_icon_alt,
    expert_title,
    expert_text,
    expert_button_text,
    expert_button_url,
  };

  const TeachersResourcesSectionProps = {
    teachers_title,
    teachers_text,
    teachers_button_text,
    teachers_button_url,
    teachers_background_image_url,
  };

  const ClubsSectionProps = {
    clubs_title,
    clubs_mentor_title,
    clubs_mentor_text,
    clubs_mentor_button_text,
    clubs_mentor_button_url,
    clubs_ambassador_title,
    clubs_ambassador_text,
    clubs_ambassador_button_text,
    clubs_ambassador_button_url,
    clubs_background_image_url,
  };

  return (
    <>
      <SEO
        title={data.directus.page_badges.hero_title}
        description={data.directus.page_badges.hero_text}
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: data.directus.page_badges.hero_title,
          description: data.directus.page_badges.hero_text,
        }}
      />
      <HeroSection {...HeroSectionProps} />
      <ExpertBadgesSection {...ExpertBadgesSectionProps} />
      <TeachersResourcesSection {...TeachersResourcesSectionProps} />
      <ClubsSection {...ClubsSectionProps} />
    </>
  );
};

export default Badges;
